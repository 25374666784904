import "styles/pages/page-post.scss";

import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";

const Post = ({ pageContext, data }) => {
	const dataPage = pageContext.data;

	return (
		<Layout headerColor="#0260DB">
			<Seo title={pageContext.name} />
			<SubpageHeader
				image={require("assets/images/blog-top-bg.jpg").default}
				borderColor="#0260DB"
			/>

			<section className="section">
				<div className="container">
					<h2 className="section-title">
						Kategoria: {dataPage.name}
					</h2>
				</div>
			</section>

			<section className="blog">
				<div className="container">
					<div className="row">
						{data.allPosts.nodes.length > 0 ? (
							data?.allPosts?.nodes?.map((item, index) => (
								<div className="col-md-4" key={index}>
									<div className="blog-tile">
										<div
											className="blog-tile__thumb"
											style={{
												backgroundImage: `url(${
													item.featuredImage &&
													item.featuredImage.node
														.sourceUrl
												})`,
											}}
										></div>
										<div className="blog-tile__info">
											<div>
												<div className="blog-tile__user"></div>
											</div>
											<data>
												{item.date
													.split("T")[0]
													.split("-")
													.reverse()
													.join(".")}
											</data>
										</div>
										<div className="blog-tile__inner">
											<h4>{item.title}</h4>
											<div
												dangerouslySetInnerHTML={{
													__html: item.excerpt,
												}}
											/>
											<Link
												to={`/blog/${item.slug}/`}
												className="button button--yellow"
											>
												Czytaj więcej
											</Link>
										</div>
									</div>
								</div>
							))
						) : (
							<div className="col-12">
								Brak pozycji w kategori...
							</div>
						)}
					</div>
				</div>
			</section>
		</Layout>
	);
};

export const query = graphql`
	query ($name: String!) {
		allPosts: allWpPost(
			filter: {
				categories: { nodes: { elemMatch: { name: { eq: $name } } } }
			}
		) {
			nodes {
				id
				slug
				uri
				title
				date
				excerpt
				featuredImage {
					node {
						sourceUrl
					}
				}
			}
		}
	}
`;

export default Post;
